import { gql } from 'graphql-tag'
import { BDESE_FRAGMENT } from './bdeseFragment'

export const CREATE_BDESE_MUTATION = gql`
  ${BDESE_FRAGMENT}
  mutation CREATE_BDESE_MUTATION(
    $year: Int!
    $realizedYearsNumber: Int!
    $trendYearsNumber: Int!
    $bdeseFilters: [ScopeFilterInputDto!]!
    $isFiscal: Boolean!
    $sections: [BdeseSectionInputDto!]!
  ) {
    createBdese(
      year: $year
      realizedYearsNumber: $realizedYearsNumber
      trendYearsNumber: $trendYearsNumber
      bdeseFilters: $bdeseFilters
      isFiscal: $isFiscal
      sections: $sections
    ) {
      ...BdeseFields
    }
  }
`
