import { gql } from 'graphql-tag'
import { BDESE_FRAGMENT } from './bdeseFragment'

export const CREATE_BDESE_SECTION_MUTATION = gql`
  ${BDESE_FRAGMENT}
  mutation CREATE_BDESE_SECTION_MUTATION(
    $id: String!
    $section: BdeseSectionInputDto!
  ) {
    createBdeseSection(id: $id, section: $section) {
      ...BdeseFields
    }
  }
`
